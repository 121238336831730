<template>
    <div>
        <div class="white-space"></div>
        <div class="quiz-title gray-bg">
            <div
                class="
                    container
                    d-flex
                    flex-column flex-sm-row
                    justify-content-between
                    align-items-center
                "
            >
                <div class="mb-3 mb-sm-0">
                    <h3>Quiz Results</h3>
                    <p class="text-capitalize font-weight-600 mb-0">
                        {{ this.$store.state.quiz.quizData.name }}
                    </p>
                </div>
                <router-link class="btn btn-brand" to="/">
                    {{ $t("home") }}
                </router-link>
            </div>
        </div>
        <div class="quiz-user-info">
            <div class="container">
                <!-- success -->
                <div
                    v-if="result.is_sucess"
                    class="
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                        text-center
                    "
                >
                    <div class="badge-result">
                        <span class="percent success"
                            >{{ result.degree }}%</span
                        >
                        <img
                            width="150"
                            src="@/assets/imgs/result-success.png"
                            alt="badge"
                        />
                    </div>
                    <h1 class="my-2 congrats">Congratulations</h1>
                    <p class="text-center">
                        {{ result.message }}
                    </p>
                    <a
                        v-if="result.certif"
                        @click="downloadImage()"
                        href="javascript:;"
                        class="btn btn-quiz mt-4"
                    >
                        Download CME‏ Accredited ‏
                    </a>
                </div>

                <!-- faild -->
                <div
                    v-else
                    class="
                        d-flex
                        flex-column
                        justify-content-center
                        align-items-center
                        text-center
                    "
                >
                    <div class="badge-result">
                        <span class="percent faild">{{ result.degree }}%</span>
                        <img
                            width="150"
                            src="@/assets/imgs/result-faild.png"
                            alt="badge"
                        />
                    </div>
                    <h1 class="my-2 sorry">Sorry</h1>
                    <p class="mb-4 text-center">{{ result.message }}</p>
                    <!-- <span class="text-gray mb-1">1/3</span> -->
                    <!-- :to="`/quiz/${this.code}`" -->
                    <button
                        @click="replayQuiz()"
                        class="btn text-brand reply-quiz"
                        :disabled="replaying"
                    >
                        <span class="text-brand">Replay this Quiz Again</span>‏
                        <b-spinner
                            v-if="replaying"
                            variant="primary"
                            label="Spinning"
                        ></b-spinner>
                    </button>
                    <div v-if="errorMsg" class="my-3">
                        <b-alert show variant="danger">{{ errorMsg }}</b-alert>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { sendUserFormData } from "@/API/Quiz";
export default {
    name: "Result",
    data() {
        return {
            replaying: false,
            errorMsg: null,
        };
    },
    computed: {
        code() {
            return this.$route.params?.code || 0;
        },
        result() {
            return this.$store.state.quiz?.result || null;
        },
        collectedData() {
            return this.$store.state.quiz?.collectedData;
        },
        verified() {
            return this.$route.query.verified || false
        },
        responseID() {
            return this.$route.query.response_id || false
        },
    },
    methods: {
        replayQuiz() {
            // if (this.verified) {
                

            //     return;
            // }
            if (!this.collectedData) return;

            this.replaying = true;
            this.errorMsg = null;
            sendUserFormData(this.collectedData).then((res) => {
                console.log(res);
                this.replaying = false;
                if (res.status == 200) {
                    // console.log(res.data);
                    let resID = res.data?.details
                    let replayQuizURL = `/quiz/${this.code}`

                    if (this.verified) {
                        resID = parseInt(this.responseID)

                        const {quiz_id, partner_id, speciality_id} = this.collectedData

                        replayQuizURL = `/quiz/${this.code}?quiz_id=${quiz_id}&partner_id=${partner_id}&speciality_id=${speciality_id}&verified=true`
                    }

                    this.$store.commit("quiz/UPDATERESPONSEID", resID)
                    this.$router.push(replayQuizURL);

                    return;
                }

                if (res?.status == 500) {
                    this.$router.push("/500");
                    return;
                }

                if (res.response?.data?.message) {
                    this.errorMsg = res.response.data.message;
                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                    });
                }
            });
        },
        async downloadImage() {
            const url = this.result.certif;

            const a = document.createElement("a");
            a.download = this.result.status;
            a.href = url;

            a.style.display = "hidden";

            document.querySelector("body").appendChild(a);

            a.click();
        },
    },
    mounted() {
        if (!this.result) {
            this.$router.push(`/quiz/${this.code}`);
        }
    },
};
</script>

